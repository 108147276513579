@import '~styles/utils/modules-entry';

$background-color: #e9e9e9;
$size: 1.5rem;
$padding: 0.3rem;
$margin-bottom: 0.15rem;
$width: 4 * $size + 2 * $padding;
$arrow-width: 1rem;
$arrow-height: 1.5rem;

.container {
  position: relative;
}

:global(.btn).moduleColor {
  $button-size: 1.25rem;

  width: $button-size;
  height: $button-size;
  padding: 0;
  border: 0;

  &.hidden {
    border: 1px dashed var(--gray-light);
    background: none;
  }
}

.palette {
  position: absolute;
  z-index: $zindex-dropdown;
  display: flex;
  flex-wrap: wrap;
  width: $width;
  // Remove last margin-bottom
  padding: $padding $padding ($padding - $margin-bottom);
  margin-top: $arrow-width;
  margin-left: -$width*0.5 + $arrow-width*0.5 + 0.15rem;
  border-radius: 5px;
  background: $background-color;
  box-shadow: 0 6px 18px rgba(#000, 0.14), 0 1px 2px rgba(#000, 0.24);

  &.isClosed {
    display: none;
  }

  // arrowtip
  &::before {
    content: '';
    position: absolute;
    top: -($arrow-height * 0.9);
    left: $width*0.5 - $arrow-width;
    height: $arrow-height;
    border: $arrow-width solid $background-color;
    border-top-width: 0;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  @include media-breakpoint-down(sm) {
    left: $width*0.5 - $arrow-width;

    &::before {
      left: 0;
    }
  }
}

.option {
  composes: btn from global;
  position: relative;
  width: $size;
  height: $size;
  margin-bottom: $margin-bottom;

  // Add a cross mark to indicate the currently selected color
  &.selected::after {
    content: '✕';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: $size * 0.8;
    line-height: $size;
    text-align: center;
  }
}

// Override .btn styles
:global(.btn).option {
  border: 0;
  border-radius: 0;
}
